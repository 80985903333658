<template>
  <b-overlay rounded="sm" no-fade>
    <div
      v-if="
        crypto ==
          'coJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvYXBpLm1yb3JoYW4uY29tXC9wdWJsaWNcL2FwaVwvbG9naW4iLCJpYXQiOjE2NTU5Nzg2MDMsImV4cCI6MzU0ODEzODYwMywibmJmIjoxNjU1OTc4NjAzLCJqdGkiOiJTV2E3dk1OcmlkV3F4MTNCIiwic3ViIjoxMDAwMDAsInBydiI6IjIzYmQ1Yzg5NDlmNjAwYWRiMzllNzAxYzQwMDg3MmRiN2E1OTc2ZjcifQ.jAlLm0LlTm4-n_2QKWgRLZmyvcroBri_I1md4v3UB_w'
      "
    >
      <b-tabs align="right">
        <b-tab active title="2025">
          <home-2025></home-2025>
        </b-tab>


        <b-tab title="2024">
          <home-2024></home-2024>
        </b-tab>

        <b-tab title="2023">
          <home-2023></home-2023>
        </b-tab>

        <b-tab title="2022">
          <home-2022></home-2022>
        </b-tab> 
      </b-tabs>
    </div>

    <div v-else>
      Error
    </div>
  </b-overlay>
</template>

<script>
import { BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody, BLink } from 'bootstrap-vue';

import { BTabs, BTab } from 'bootstrap-vue';
import homeStoreModule from './homeStoreModule';
import store from '@/store';
import { ref, onUnmounted, reactive } from '@vue/composition-api';
import router from '@/router';
import Home2022 from './Home2022.vue';
import Home2023 from './Home2023.vue';
import Home2024 from './Home2024.vue';
import Home2025 from './Home2025.vue';

import { $themeColors } from '@themeConfig';

export default {
  components: {
    BLink,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BTabs,
    BTab,
    Home2022,
    Home2023,
    Home2024,
    Home2025,

  },

  setup() {
    const HOME_APP_STORE_MODULE_NAME = 'home';
    // Register module
    if (!store.hasModule(HOME_APP_STORE_MODULE_NAME)) store.registerModule(HOME_APP_STORE_MODULE_NAME, homeStoreModule);
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(HOME_APP_STORE_MODULE_NAME)) store.unregisterModule(HOME_APP_STORE_MODULE_NAME);
    });
  },

  created() {},

  watch: {},

  data() {
    return {
      crypto: router.currentRoute.params.crypto,
    };
  },

  methods: {
    changeYear(val) {
      if (val == 1) {
        console.log('1');
      } else {
        console.log('2');
      }
    },
  },
};
</script>
