import axiosIns from '@/libs/axios';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getPurchaseInformation(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .post('purchaseQuoteInformation')
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getFormList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns

          .get('allFormShow')
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getFormList2023(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns

          .get('allFormShow2023')
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getFormList2024(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns

          .get('allFormShow2024')
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    getFormList2025(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns

          .get('allFormShow2025')
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    fetchSettings(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .get('settings', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },
  },
};
